@import "./scss/style.scss";

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}

.text-muted {
    opacity: 0.8;
}

.link-button {
    color: #1677ff;
    cursor: pointer;
}

.buttons {
    white-space: pre;
    .ant-btn {
        margin-left: 5pt;
    }
}

.d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-modal-content {
    background-color: rgba(255, 255, 255);

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 13px;

        .ant-btn {
            margin-left: 13px;
        }
    }
}

.no-select {
    user-select: none;
}
