.tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-tools {
    margin-bottom: 13pt;
}

.data-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5pt;
    .data-title {
        opacity: 0.8;
        padding: 3pt 0;
        padding-right: 21px;
        min-width: 144px;
    }
    .data-value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        .ant-btn {
            margin-left: 5pt;
        }
    }
}

.setting-section {
    margin-bottom: 21px;
}
.setting-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .setting-key {
        font-weight: bold;
    }

    input {
        max-width: 144px;
    }
}

.section-header {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .section-title {
        font-weight: bold;
        font-size: large;
    }
}
